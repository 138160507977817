/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";

const StepEight = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const [hasError, setHasError] = useState(false)
  const [agencyPhone, setAgencyPhone] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const userInfo = useSelector(state => state.userInfo)
  console.log({userInfo})
  const LANGUAGE = userInfo.language
  const [hasFace, setHasFace] = useState(false);
  const [pressAnalysis, setPressAnalysis] = useState(false);
  const [scanCode, setScanCode] = useState('');
  const videoScan = useRef()

  useEffect(() => {
    window.addEventListener('message', function(event) {
      console.log(event.data); // Message received from child
      if (event.data == '11111') {
        setHasFace(true)
      } else if (!pressAnalysis) {
        setHasFace(false)
      }
    });
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div style={{ backgroundColor: '#000', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#000',
        }}
      >



        <div
          style={{
            width: '100%',
            padding: '0px'
          }}
        >
          {(hasFace && !hasError && !pressAnalysis) && <>
            <button
              type="button"
              style={{
                width: '100%',
                padding: '25px',
                backgroundColor: (hasFace && !hasError) ? '#30a3fc' : '#a1e6b9',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                borderRadius: '10px',
                borderWidth: '0px'
              }}
              onClick={() => {
                axios.post('https://backend.robotanan.com/api/character/auth-scan-code', {
                  phone: userInfo.phone,
                  scan_code: userInfo.scan_code,
                  data: Math.floor(Math.random() * 100000000000000000000000000000),
                }).then(data => data)
                  .then((resData) => {
                    console.log({resData})
                    setPressAnalysis(true)
                    videoScan.current.play()
                    if (resData.data.error_code === 1) {
                      dispatch({
                        type: 'SET_CHARACTER',
                        payload: {
                          characterId: resData.data.data.character_id,
                        }
                      })
                    } else {
                      setHasError(true)
                      setErrorMsg('Phân tích không thành công, vui lòng thử lại hoặc liên hệ với quản trị viên!')
                    }
                  })
                  .catch(err => {
                    console.log(err)
                    setHasError(true)
                    setErrorMsg('Phân tích không thành công, vui lòng thử lại hoặc liên hệ với quản trị viên!')
                  })
              }}
            >
            <span
              style={{
                fontSize: '24px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMA',
                marginTop: '5px',
                marginBottom: '5px'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'BẮT ĐẦU PHÂN TÍCH'
                :
                'START ANALYSIS'
              }
            </span>
            </button>
          </>}

          {userInfo.phone && userInfo.name &&
            <p className='text-success text-center'>{userInfo.phone} + {userInfo.name}</p>
          }

          <div>
            <iframe src="https://face.robotanan.com/" style={{ width: '100%', height: '540px', display: pressAnalysis ? 'none' : 'block', overflow: 'hidden' }} allow="camera" />
          </div>

          <video style={{display: !pressAnalysis ? 'none' : 'block' }} ref={videoScan} onEnded={() => { if (!hasError) history.push('/note-login')}} className='w-100' src={"https://backend.robotanan.com/character/scan.mp4"} />


          {errorMsg && (<h1 style={{ color: 'red', fontFamily: 'UTM-APTIMA' }} >{errorMsg}</h1>)}

        </div>




      </div>

    </div>
  )
}

export {StepEight}
