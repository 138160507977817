import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";

const NoteLogin = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth
  const HEIGHT = window.innerWidth > 400 ? '400px' : window.innerHeight

  const [birthDay, setBirthDay] = useState('');
  const [birthMonth, setBirthMonth] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [language, setLanguage] = useState('male');
  const [isChild, setIsChild] = useState(0);
  const [provinceId, setProvinceId] = useState(1);
  const [districtId, setDistrictId] = useState(1);
  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [currentDistricts, setCurrentDistricts] = useState([])
  const [isCorrect, setIsCorrect] = useState(true);
  const [hasModal, setHasModal] = useState(false);

  const userInfo = useSelector(state => state.userInfo)
  console.log(userInfo)
  const LANGUAGE = userInfo.language

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >

        <div
          style={{
            width: '100%',
            padding: '30px'
          }}
        >
          <div>
            <p
              style={{
                fontSize: '24px',
                fontFamily: 'UTM-APTIMA',
                color: '#000',
              }}
            >>> Mời bạn liên hệ với cộng tác viên của Anbi qua số điện thoại <span className='text-danger'>{ userInfo.sale.note ? userInfo.sale.note : userInfo.sale.phone }</span> để được hỗ trợ mở khóa bản phân tích. </p>
          </div>

          <div
            style={{
              paddingTop: '20px'
            }}
          >
            <button
              style={{
                width: '100%',
                padding: '15px',
                backgroundColor: '#50f820',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                borderRadius: '15px',
                borderWidth: '0px',
                display: 'block',
                marginTop: '20px'
              }}
              onClick={() => {
                window.open('https://user.robotanan.com/auth/login?phone=' + userInfo.phone, '_self').focus()
              }}
            >
            <span
              style={{
                fontSize: '20px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              ĐĂNG NHẬP TÀI KHOẢN
            </span>
            </button>
          </div>

        </div>


      </div>

    </div>
  )
}

export {NoteLogin}
