/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";
import {SET_STEP_ONE} from "../../setup/redux/userReducer";

const StepOne = () => {
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth
  const history = useHistory()

  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.userInfo)
  const LANGUAGE = userInfo.language

  const [name, setName] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [phone, setPhone] = useState('');
  const [familyPhone, setFamilyPhone] = useState('');
  const [familyRole, setFamilyRole] = useState('');
  const [familyRoles, setFamilyRoles] = useState([]);
  const [agencyAlias, setAgencyAlias] = useState('');
  const [isCorrect, setIsCorrect] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [gender, setGender] = useState('male');
  const [hasModal, setHasModal] = useState(true);
  const [hasModalWarning, setHasModalWarning] = useState(false);
  const [questionOne, setQuestionOne] = useState('');
  const [questionTwo, setQuestionTwo] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (name && phone && agencyAlias) {
      setIsCorrect(true)
    } else {
      setIsCorrect(false)
    }
  }, [name, phone, agencyAlias, familyPhone, familyRole, birthYear])

  const checkFamilyPhone = () => {
    if (familyPhone) {
      axios.post(`${BACKEND_URL}/family-role`, {
        family_phone: familyPhone
      }).then(res => res.data)
        .then(resData => {
          setFamilyRoles(resData.data.availableRoles)
          if (resData.data.availableRoles) {
            setFamilyRole(resData.data.availableRoles[0])
          }
        })
        .catch(err => console.log(err))
    }
  }

  const confirmRegister = () => {
    setIsCorrect(false)
    axios.post(`${BACKEND_URL}/customer/free-register`, {
      name: name,
      phone: phone,
      password: phone,
      agency_alias: agencyAlias,
      birth_month: userInfo.birth_month,
      birth_year: userInfo.birth_year,
      gender: gender,
      province_id: userInfo.province_id,
      district_id: userInfo.district_id,
      is_child: userInfo.is_child,
      question_one: questionOne,
      question_two: questionTwo,
      family_phone: familyPhone,
      family_role: familyRole,
    }).then(res => res.data)
      .then(resData => {
        setIsCorrect(true)
        console.log({resData})
        if (resData.error_code === 1) {
          dispatch({
            type: SET_STEP_ONE,
            payload: {
              name: name,
              birthYear: birthYear,
              phone: phone,
              password: phone,
              agencyAlias: agencyAlias,
              familyPhone: familyPhone,
              familyRole: familyRole,
              gender: gender,
              scanCode: resData.data.new_user.scan_code,
              sale: resData.data.agency,
            }
          })
          history.push('/note-scan')
        } else {
          setErrorMsg(resData.message ?? 'Đăng ký không thành công, vui lòng thử lại hoặc liên hệ với quản trị viên!')
        }
      })
      .catch(err => {
        console.log({err})
        setErrorMsg('Lỗi hệ thống, vui lòng thử lại hoặc liên hệ với quản trị viên!')
      })
  }

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >

        <div>
          <img src={toAbsoluteUrl('/media/logos/company.jpg')} style={{ width: WIDTH }} alt='intro' />
        </div>

        <div
          style={{
            width: '100%',
            padding: '30px'
          }}
        >
          <div>
            <p
              style={{
                fontSize: '18px',
                fontFamily: 'UTM-APTIMA',
                color: '#aeafce',
              }}
            >>> Tìm hiểu thông tin về công nghệ Anbi </p>
            <button
              onClick={() => {
                history.push('/intro2')
              }}
              type="button"
              style={{
                width: '100%',
                padding: '15px',
                backgroundColor: '#0dc735',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                borderRadius: '10px',
                borderWidth: '0px',
                fontFamily: 'UTM-APTIMA'
              }}
            >
            <span
              style={{
                fontSize: '22px',
                fontFamily: 'UTM-APTIMABOLD',
                color: '#ffffff',
                letterSpacing: 2.0
              }}
            >
              TÌM HIỂU ANBI
            </span>
            </button>
          </div>

          <div
            style={{
              paddingTop: '20px'
            }}
          >
            <p
              style={{
                fontSize: '18px',
                fontFamily: 'UTM-APTIMA',
                color: '#aeafce',
              }}
            >>> Đăng nhập nếu bạn đã có tài khoản </p>
            <a href="https://user.robotanan.com/auth/login" target='_blank'>
              <button
                type="button"
                style={{
                  width: '100%',
                  padding: '15px',
                  backgroundColor: '#0dc735',
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center',
                  borderRadius: '10px',
                  borderWidth: '0px',
                  fontFamily: 'UTM-APTIMA'
                }}
              >
              <span
                style={{
                  fontSize: '22px',
                  fontFamily: 'UTM-APTIMABOLD',
                  color: '#ffffff',
                  letterSpacing: 2.0
                }}
              >
                ĐĂNG NHẬP ANBI
              </span>
              </button>
            </a>
          </div>

        </div>

        {/*<div>*/}
        {/*  <img src={toAbsoluteUrl('/media/logos/note.jpg')} style={{ width: WIDTH }} alt='intro' />*/}
        {/*</div>*/}


        <div
          style={{
            width: '100%',
            padding: '0px 30px 20px 30px'
          }}
        >
          <div
            style={{
              display: hasModal ? 'block' : 'none',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
            }}
          >
          </div>

          <div
            style={{
              display: hasModal ? 'block' : 'none',
              position: 'fixed',
              width: '350px',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              textAlign: 'center',
              borderRadius: '10px',
              fontFamily: 'UTM-APTIMABOLD',
              overflow: 'scroll',
              padding: '20px'
            }}
          >
            <img src={toAbsoluteUrl('/media/logos/note-web.png')} style={{ width: '100%', height: 'auto' }} alt='' />

            <div
              onClick={() => setHasModal(false)}
              style={{
                width: '60%',
                border: '1px solid #ddd',
                borderRadius: '10px',
                fontSize: '22px',
                fontWeight: 'bolder',
                backgroundColor: '#30a3fc',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                color: 'white',
                fontFamily: 'UTM-APTIMABOLD',
                margin: 'auto',
                marginTop: '20px',
                cursor: 'pointer'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'TÔI ĐÃ HIỂU'
                :
                'I UNDERSTAND'
              }
            </div>

            <div style={{ height: '20px' }}></div>

          </div>

          <div
            style={{
              display: hasModalWarning ? 'block' : 'none',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(89, 89, 89, 0.5)',
            }}
          >
          </div>

          <div
            style={{
              display: hasModalWarning ? 'block' : 'none',
              position: 'fixed',
              width: '380px',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              textAlign: 'center',
              borderRadius: '10px',
              fontFamily: 'UTM-APTIMABOLD',
              paddingBottom: '20px',
              height: '100%',
              overflow: 'scroll'
            }}
          >
            <img src={toAbsoluteUrl('/media/logos/canh-bao-anbi.jpg')} style={{ width: '340px', height: 'auto'}} alt='' />

            <div
              onClick={() => {
                // cho ve section id khao sat
                setHasModalWarning(false)
                const element = document.getElementById('survey-section');
                element?.scrollIntoView({
                  behavior: 'smooth'
                });
              }}
              style={{
                width: '90%',
                border: '1px solid #ddd',
                borderRadius: '10px',
                fontSize: '20px',
                fontWeight: 'bold',
                backgroundColor: '#30a3fc',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                color: 'white',
                marginTop: '20px',
                marginBottom: '20px',
                fontFamily: 'UTM-APTIMA',
                margin: 'auto',
                cursor: 'pointer'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'TIẾP TỤC KHẢO SÁT'
                :
                'CONTINUE SURVEY'
              }
            </div>

            <div style={{ height: '16px' }}></div>

            <div
              onClick={() => {
                // tiep tuc dang ky
                setHasModalWarning(false)
                confirmRegister()
              }}
              style={{
                width: '90%',
                border: '1px solid #ddd',
                borderRadius: '10px',
                fontSize: '20px',
                fontWeight: 'bold',
                backgroundColor: '#d0d0d0',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                color: 'white',
                marginTop: '20px',
                marginBottom: '20px',
                fontFamily: 'UTM-APTIMA',
                margin: 'auto',
                cursor: 'pointer'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'XÁC NHẬN BỎ QUA'
                :
                'CONFIRM SKIP'
              }
            </div>

          </div>

          <div>
            <p
              style={{
                color: '#0dc735',
                fontSize: '24px',
                textAlign: 'justify',
                fontFamily: 'UTM-APTIMABOLD',
                marginTop: '20px'
              }}
            >
              Lưu ý quan trọng
            </p>
            <p
              style={{
                fontSize: '18px',
                fontWeight: 'bolder',
                fontFamily: 'UTM-APTIMA',
                color: '#2d2d2d',
              }}
            >
              1. Công nghệ Anbi đang trong giai đoạn hoàn thiện nghiên cứu nên mời bạn sẽ chỉ tham gia phân tích 01 lần duy nhất để tránh xung đột dữ liệu của dự án và của chính bạn, vì từ lần quét thứ hai cơ chế máy học (machine learning) của Anbi sẽ hiểu là bạn chưa hài lòng với lần đầu tiên, và sẽ liệt kê các nhóm khác khiến thông tin không còn đồng nhất.
            </p>
            <p
              style={{
                fontSize: '18px',
                fontWeight: 'bolder',
                fontFamily: 'UTM-APTIMA',
                color: '#2d2d2d',
              }}
            >
              2. Bản phân tích tính cách bằng công nghệ Anbi bao gồm báo cáo 12 thông số về tính cách, các khóa học trực tuyến tặng kèm và bản đồ giao tiếp trong gia đình có giá niêm yết 60 USD (1.600.000 VNĐ). Bạn vui lòng nhập mã quà tặng để nhận chính sách ưu đãi.
            </p>
            <p
              style={{
                fontSize: '18px',
                fontWeight: 'bolder',
                fontFamily: 'UTM-APTIMA',
                color: '#2d2d2d',
              }}
            >
              3. Trước khi tiến hành phân tích khuôn mặt, mời bạn đóng tất cả các ứng dụng khác trên điện thoại hoặc máy tính của mình.
            </p>
            <p
              style={{
                fontSize: '18px',
                fontWeight: 'bolder',
                fontFamily: 'UTM-APTIMA',
                color: '#2d2d2d',
              }}
            >
              Xin cảm ơn!
            </p>
          </div>

          <p style={{
            color: '#2d308f',
            fontSize: '30px',
            fontFamily: 'UTM-APTIMABOLD',
          }}>
            {LANGUAGE === 'VI'
              ?
              'Thông tin'
              :
              'Information'
            }
          </p>
          <p style={{
            color: '#008ffe',
            fontSize: '40px',
            fontFamily: 'UTM-APTIMABOLD',
            marginTop: '-20px'
          }}>
            {LANGUAGE === 'VI'
              ?
              'ĐĂNG KÝ'
              :
              'REGISTER'
            }
          </p>

          <h3
            style={{ color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}
          >
            {LANGUAGE === 'VI'
              ?
              '1. Họ và tên'
              :
              '1. Full name'
            }
          </h3>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '25px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMA',
              marginTop: '10px'
            }}
          />

          <h3 style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}>
            {LANGUAGE === 'VI'
              ?
              '2. Năm sinh'
              :
              '2. Year of Birth'
            }
          </h3>
          <input
            value={birthYear}
            onChange={(e) => setBirthYear(e.target.value)}
            type="number"
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '25px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMA',
              marginTop: '10px'
            }}
          />

          <h3 style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}>
            {LANGUAGE === 'VI'
              ?
              '3. Giới tính'
              :
              '3. Gender'
            }
          </h3>
          <select
            onChange={(e) => setGender(e.target.value)}
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMABOLD',
              marginTop: '10px'
            }}
          >
            <option key='male' value='male' style={{ fontFamily: 'UTM-APTIMA' }}>
              {LANGUAGE === 'VI'
                ?
                'Nam'
                :
                'Male'
              }
            </option>
            <option key='female' value='female' style={{ fontFamily: 'UTM-APTIMA' }}>
              {LANGUAGE === 'VI'
                ?
                'Nữ'
                :
                'Female'
              }
            </option>
          </select>

          <h3 style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}>
            {LANGUAGE === 'VI'
              ?
              '4. Số điện thoại'
              :
              '4. Phone number'
            }
          </h3>
          <input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="number"
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '25px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMA',
              marginTop: '10px'
            }}
          />
          <p className='d-none' style={{fontSize: '18px', color: '#3d99ff', fontFamily: 'UTM-APTIMA', textAlign: 'justify' }}>* Nếu xảy ra trường hợp một gia đình chỉ có 1 SĐT nhưng lại có nhiều thành viên tham gia phân tích, bạn có thể thêm vào phía sau 1 hoặc 2 số nữa để tránh trùng lặp</p>

          <h3 style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}>
            {LANGUAGE === 'VI'
              ?
              '5. Mã quà tặng'
              :
              '5. Gift code'
            }
          </h3>
          <input
            value={agencyAlias}
            onChange={(e) => setAgencyAlias(e.target.value)}
            type="text"
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '25px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMA',
              marginTop: '10px'
            }}
          />

          <h3 className='' style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}>
            {LANGUAGE === 'VI'
              ?
              '6. Mã số gia đình'
              :
              '6. Family code'
            }
          </h3>
          <input
            value={familyPhone}
            onChange={(e) => setFamilyPhone(e.target.value)}
            onBlur={() => {
              checkFamilyPhone()
            }}
            type="number"
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '25px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMA',
              marginTop: '10px'
            }}
          />
          <p style={{marginTop: '18px', color: '#333', fontFamily: 'UTM-APTIMA', fontSize: '16px', }}>
            Mã gia đình (mỗi gia đình cần 1 mã số duy nhất để phần mềm kết nối thông tin các thành viên tạo bản đồ giao tiếp trong gia đình – là công cụ quan trọng giúp cả nhà hiểu sâu sắc về nhau, bạn nên chọn SĐT của người mẹ để làm mã này)
          </p>

          <h3 style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}>
            {LANGUAGE === 'VI'
              ?
              '7. Vai trò trong gia đình'
              :
              '7. Choose family role'
            }
          </h3>
          <select
            onChange={(e) => setFamilyRole(e.target.value)}
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMABOLD',
              marginTop: '10px'
            }}
          >
            {familyRoles.length > 0 && familyRoles.map((familyRole) =>
              <option key={familyRole} value={familyRole} style={{ fontFamily: 'UTM-APTIMABOLD' }}>
                {familyRole === 'FATHER' && 'Bố (Chồng)'}
                {familyRole === 'MOTHER' && 'Mẹ (Vợ)'}
                {familyRole === 'CHILD_ONE' && 'Con thứ nhất'}
                {familyRole === 'CHILD_TWO' && 'Con thứ hai'}
                {familyRole === 'CHILD_THREE' && 'Con thứ ba'}
                {familyRole === 'CHILD_FOUR' && 'Con thứ tư'}
                {familyRole === 'CHILD_FIVE' && 'Con thứ năm'}
              </option>
            )}
          </select>

          <p
            style={{
              color: '#0dc735',
              fontSize: '24px',
              textAlign: 'justify',
              fontFamily: 'UTM-APTIMABOLD',
              marginTop: '20px',
              display: 'none'
            }}
          >
            Bạn muốn được học với mẫu giáo viên như nào nhất trong các khóa học trực tuyến quà tặng của chúng tôi ?
          </p>

          <div
            style={{
              display: 'none'
            }}
          >
            <div
              onClick={() => {setQuestionOne("ONE_A")}}
              style={{
                width: '100%',
                border: '1px solid #ececec',
                borderRadius: '10px',
                fontSize: '18px',
                fontWeight: 'bolder',
                color: questionOne === "ONE_A" ? 'white' : '#2d2d2d',
                textAlign: 'left',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px',
                backgroundColor: questionOne === "ONE_A" ? '#0391ff' : '#ececec',
                fontFamily: 'UTM-APTIMABOLD',
                marginTop: '20px',
              }}
            >
              Truyền cảm hứng mạnh mẽ và có thưởng phạt rõ ràng.
            </div>

            <div
              onClick={() => {setQuestionOne("ONE_B")}}
              style={{
                width: '100%',
                border: '1px solid #ececec',
                borderRadius: '10px',
                fontSize: '18px',
                fontWeight: 'bolder',
                color: questionOne === "ONE_B" ? 'white' : '#2d2d2d',
                textAlign: 'left',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px',
                backgroundColor: questionOne === "ONE_B" ? '#0391ff' : '#ececec',
                fontFamily: 'UTM-APTIMABOLD',
                marginTop: '20px',
              }}
            >
              Giàu kiến thức và kinh nghiệm như một chuyên gia.
            </div>

            <div
              onClick={() => {setQuestionOne("ONE_C")}}
              style={{
                width: '100%',
                border: '1px solid #ececec',
                borderRadius: '10px',
                fontSize: '18px',
                fontWeight: 'bolder',
                color: questionOne === "ONE_C" ? 'white' : '#2d2d2d',
                textAlign: 'left',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px',
                backgroundColor: questionOne === "ONE_C" ? '#0391ff' : '#ececec',
                fontFamily: 'UTM-APTIMABOLD',
                marginTop: '20px',
              }}
            >
              Ấm áp và sẵn sàng chia sẻ tâm sự với học viên.
            </div>
          </div>

          <p
            style={{
              color: '#0dc735',
              fontSize: '24px',
              textAlign: 'justify',
              fontFamily: 'UTM-APTIMABOLD',
              marginTop: '20px'
            }}
          >
            Bạn tự thấy mình có mức độ hạnh phúc như nào ?
          </p>

          <div>
            <div
              onClick={() => {setQuestionTwo("TWO_A")}}
              style={{
                width: '100%',
                border: '1px solid #ececec',
                borderRadius: '10px',
                fontSize: '18px',
                fontWeight: 'bolder',
                color: questionTwo === "TWO_A" ? 'white' : '#2d2d2d',
                textAlign: 'left',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px',
                backgroundColor: questionTwo === "TWO_A" ? '#0391ff' : '#ececec',
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'Mức 1: Rất hạnh phúc và luôn cảm thấy biết ơn'
                :
                'Level 1: Happiness and deep gratitude'
              }
            </div>


            <div
              onClick={() => {setQuestionTwo("TWO_B")}}
              style={{
                width: '100%',
                border: '1px solid #ececec',
                borderRadius: '10px',
                fontSize: '18px',
                fontWeight: 'bolder',
                color: questionTwo === "TWO_B" ? 'white' : '#2d2d2d',
                textAlign: 'left',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px',
                backgroundColor: questionTwo === "TWO_B" ? '#0391ff' : '#ececec',
                fontFamily: 'UTM-APTIMABOLD',
                marginTop: '20px',
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'Mức 2: Cảm thấy hài lòng và thoải mái'
                :
                'Level 2: Satisfied and comfortable'
              }
            </div>

            <div
              onClick={() => {setQuestionTwo("TWO_C")}}
              style={{
                width: '100%',
                border: '1px solid #ececec',
                borderRadius: '10px',
                fontSize: '18px',
                fontWeight: 'bolder',
                color: questionTwo === "TWO_C" ? 'white' : '#2d2d2d',
                textAlign: 'left',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px',
                backgroundColor: questionTwo === "TWO_C" ? '#0391ff' : '#ececec',
                fontFamily: 'UTM-APTIMABOLD',
                marginTop: '20px',
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'Mức 3: Cảm thấy bình thường và ổn định'
                :
                'Level 3: Normal and stable'
              }
            </div>

            <div
              onClick={() => {setQuestionTwo("TWO_D")}}
              style={{
                width: '100%',
                border: '1px solid #ececec',
                borderRadius: '10px',
                fontSize: '18px',
                fontWeight: 'bolder',
                color: questionTwo === "TWO_D" ? 'white' : '#2d2d2d',
                textAlign: 'left',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px',
                backgroundColor: questionTwo === "TWO_D" ? '#0391ff' : '#ececec',
                fontFamily: 'UTM-APTIMABOLD',
                marginTop: '20px',
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'Mức 4: Khó hài lòng và thường lo lắng'
                :
                'Level 4: Difficult to please and often anxious'
              }
            </div>

            <div
              onClick={() => {setQuestionTwo("TWO_E")}}
              style={{
                width: '100%',
                border: '1px solid #ececec',
                borderRadius: '10px',
                fontSize: '18px',
                fontWeight: 'bolder',
                color: questionTwo === "TWO_E" ? 'white' : '#2d2d2d',
                textAlign: 'left',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px',
                backgroundColor: questionTwo === "TWO_E" ? '#0391ff' : '#ececec',
                fontFamily: 'UTM-APTIMABOLD',
                marginTop: '20px',
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'Mức 5: Cảm thấy bất hạnh và thất vọng'
                :
                'Level 5: Feeling unhappy and disappointed'
              }
            </div>
          </div>

          <div
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
              fontFamily: 'UTM-APTIMA'
            }}
          >
            {errorMsg && <h3
              style={{
                color: 'red'
              }}
            >{errorMsg}
            </h3>}

          </div>

          <button
            disabled={ isCorrect ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: isCorrect ? '#0dc735' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              borderWidth: '0px'
            }}
            onClick={() => {
              if (questionTwo) {
                confirmRegister()
              } else {
                setHasModalWarning(true)
              }
            }}
          >
            <span
              style={{
                fontSize: '22px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'ĐĂNG KÝ'
                :
                'REGISTER'
              }
            </span>
          </button>


        </div>


      </div>

    </div>
  )
}

export {StepOne}
